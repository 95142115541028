import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

// FLEX LAYOUT
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalProgramComponent } from './pages/modal-program/modal-program.component';
import { ModalPartecipaComponent } from './pages/modal-partecipa/modal-partecipa.component';
import { ModalCandidatiComponent } from './pages/modal-candidati/modal-candidati.component';
import { ModalSuggerimentiComponent } from './pages/modal-suggerimenti/modal-suggerimenti.component';
import { ListaMovimentoComponent } from './pages/lista-movimento/lista-movimento.component';
import { ListaConraimondoComponent } from './pages/lista-conraimondo/lista-conraimondo.component';


//FIREBASE
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { CookieComponent } from './pages/terms/cookie/cookie.component';
import { CandidaturaComponent } from './pages/candidatura/candidatura.component';

export const firebaseConfig = {
  apiKey: "AIzaSyC6yRRJOjOp77L6qYe2bwLyMCOIwKGogAs",
    authDomain: "noicattaro5.firebaseapp.com",
    databaseURL: "https://noicattaro5-default-rtdb.firebaseio.com",
    projectId: "noicattaro5",
    storageBucket: "noicattaro5.appspot.com",
    messagingSenderId: "732279290643",
    appId: "1:732279290643:web:247efa65f54ceda9abf8e6",
    measurementId: "G-NLBJ2QT2J8"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ModalProgramComponent,
    ModalPartecipaComponent,
    ModalCandidatiComponent,
    CookieComponent,
    CandidaturaComponent,
    ModalSuggerimentiComponent,
    ListaMovimentoComponent,
    ListaConraimondoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents:[
    ModalProgramComponent,
    ModalPartecipaComponent,
    ModalCandidatiComponent,
    ModalSuggerimentiComponent,
  ]
})
export class AppModule { }