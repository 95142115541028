import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-candidatura',
  templateUrl: './candidatura.component.html',
  styleUrls: ['./candidatura.component.scss']
})
export class CandidaturaComponent implements OnInit {

  public candidaturaForm: FormGroup;
  public spinnerFile = false;
  public file = [];
  public fileLenght = 0;
  public key;

  constructor(public db: AngularFireDatabase, public formBuilder: FormBuilder, private router: Router, public snackBar: MatSnackBar, private storage: AngularFireStorage) {
    this.candidaturaForm = formBuilder.group({
      nome : new FormControl('', Validators.compose([Validators.required])),
      cognome : new FormControl('', Validators.compose([Validators.required])),
      paese : new FormControl('', Validators.compose([Validators.required])),
      indirizzo : new FormControl('', Validators.compose([Validators.required])),
      professione : new FormControl('', Validators.compose([Validators.required])),
      email : new FormControl('', Validators.compose([Validators.required])),
      telefono : new FormControl('', Validators.compose([Validators.required])),
      privacy: new FormControl(false),
    });
  }

  ngOnInit(): void {
  }

  public error;
  public spinner = true;
  send(){
    /*
    var self = this;
    this.error = null;
    if(this.candidaturaForm.valid){
      if(this.candidaturaForm.value.privacy){
        if(this.fileLenght!=0){
          this.spinner = true;
          var data = Date.now();
          var docData = {
            created_at: data,
            nome: this.candidaturaForm.value.nome,
            cognome: this.candidaturaForm.value.cognome,
            paese: this.candidaturaForm.value.paese,
            indirizzo: this.candidaturaForm.value.indirizzo,
            professione: this.candidaturaForm.value.professione,
            telefono: this.candidaturaForm.value.telefono,
            documento : this.file,
          }

          this.db.list('candidatura/').push(docData).then(function(snapshot) {
            console.log(snapshot);
            let snackBarRef = self.snackBar.open('La candidatura è stata inoltrata.');
            self.spinner = false;
            self.router.navigate(['/home']);
          });
        }else{
          this.error = "Carica un documento di identità.";
        }
      }else{
        this.error = "Accetta l'informativa sulla privacy.";
      }
    }else{
      this.error = 'Compila tutti i campi richiesti.';
    }
    */
  }

  onFileChangedFile(event) {
    this.spinnerFile=true;
    var self = this;
    let results = event.target.files;
    
    this.key = Math.floor(Math.random() * 10000); 
    let namefile = results[0].name;
    const file = results[0];
    const filePath = 'content_candidatura/'+self.key+'/'+namefile;
    const fileRef = self.storage.ref(filePath);
    const task = self.storage.upload(filePath, file);

    console.log(this.key);
    task.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(data=>{
          let myFileURL = data;
          this.fileLenght = 1;
          this.file.push({"url":myFileURL, "name_file":namefile, "html":'Descrizione File'});
          console.log(self.file);
          self.spinnerFile=false;
        });
      })
    ).subscribe(c=>{
      console.log(c);
    });
  }

  /* ELIMINAZIONE SINGOLO FILE */
  removeFile(nameFile): void {
    var key = String(this.key);
    console.log(key);
    var storageRef = this.storage.ref('content_candidatura/');
    var desertRef = storageRef.child(key).child(nameFile);
    // Delete the file
    desertRef.delete();
    this.fileLenght = 0;
    this.file = [];
    console.log(this.file);
  }

  open(url){
    window.open(url);
  }
}
