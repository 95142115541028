import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-partecipa',
  templateUrl: './modal-partecipa.component.html',
  styleUrls: ['./modal-partecipa.component.scss']
})
export class ModalPartecipaComponent implements OnInit {

  public partecipaForm: FormGroup;

  constructor(public db: AngularFireDatabase, public formBuilder: FormBuilder, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ModalPartecipaComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.partecipaForm = formBuilder.group({
      nome : new FormControl('', Validators.compose([Validators.required])),
      cognome : new FormControl('', Validators.compose([Validators.required])),
      email : new FormControl('', Validators.compose([Validators.required])),
      telefono : new FormControl('', Validators.compose([Validators.required])),
      privacy: new FormControl(false),
    });
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  public error;
  public spinner = true;
  send(){
    var self = this;
    this.error = null;
    if(this.partecipaForm.valid){
      if(this.partecipaForm.value.privacy){
        this.spinner = true;
        var data = Date.now();
        var docData = {
          created_at: data,
          nome: this.partecipaForm.value.nome,
          cognome: this.partecipaForm.value.cognome,
          email: this.partecipaForm.value.email,
          telefono: this.partecipaForm.value.telefono,
        }

        this.db.list('partecipa/').push(docData).then(function(snapshot) {
          console.log(snapshot);
          let snackBarRef = self.snackBar.open('I tuoi dati sono stati inviati.');
          self.dialogRef.close();
          self.spinner = false;
        });
      }else{
        this.error = "Accetta l'informativa sulla privacy.";
      }
    }else{
      this.error = 'Compila tutti i campi richiesti.';
    }
  }
}
