<section fxHide fxShow.md fxShow.gt-md style="width: 100%; height: 100vh; overflow: hidden; position: relative; background-image: url('./assets/img/sfondo.png');  background-repeat: repeat; background-size: 600px 70px; ">
    
    <!--
    <div style="font-size: 90px; font-weight: 900; color: #323232; width: 100%; overflow: hidden;">
        raimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamorato
    </div>

    <div style="position: relative; left:500px; transform: rotate(-90deg); font-size: 90px; font-weight: 900; color: #323232; overflow: hidden;">
        raimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamorato
    </div>
    -->

    <div fxLayout="row" fxLayoutAlign="center center" style="top: 0px; padding-top: 30px; position: relative; text-align: center; border-top: 5px solid #323232;">
        <button (click)="openLink('https://www.comune.noicattaro.bari.it/upload/Noicattaro/gestionedocumentale/PROGRAMMA-INNAMORATORAIMONDO_784_5353.pdf?')" class="button-border">Programma 2016</button>
        <button (click)="openLink('https://www.raimondoinnamorato.it/assets/media/programma_2021.pdf')" class="button-border">Programma 2021</button>
        <button (click)="openModalSuggerimenti()" class="button-border">Suggerimenti</button>
        <button routerLink="/lista-movimento" class="button-border">I candidati</button>
        <button fxLayout="row" fxLayoutAlign="center center" (click)="openModalPartecipa()" class="button-border">
            <div>Partecipa</div>
            
            <div  fxLayout="row" fxLayoutAlign="center center" style="margin-left: 15px; width: 30px; height: 25px; background-color: white; border-radius: 50%; color:#323232; font-size: 14px; font-weight: 700;">{{numero}}</div>
            
        </button>
        
        <button fxLayout="row" fxLayoutAlign="center center" routerLink="/candidatura" class="button-border">
            <div>Invia la tua candidatura</div>
        </button>
        
        <!--
        <button fxLayout="row" fxLayoutAlign="center center" (click)="openModalCandidati()" class="button-border">
            <div>Cose fatte</div>
            <div style="margin-left:15px"><i class="fas fa-check"></i></div>
        </button>
        -->
    </div>

    <div style="margin-top: 20vh; width: 100%; text-align: center;">
        <img src="./assets/img/Noicattaro.png" width="200px">
    
        <div style="margin-top: 20px; background-color: #ec1f24; padding-top: 5px; padding-bottom: 5px; color: white; font-size: 30px; font-weight: 600;">
            Noicàttaro 2016 - 2021 - 2026
        </div>
        <div style="margin-top: 10px; font-size: 20px; font-weight: 700;">
            <div class="font-lobster-44">
                Una città che cammina...
            </div><!--#stabilità &nbsp;&nbsp;&nbsp; #crescita &nbsp;&nbsp;&nbsp; #correttezza-->
        </div>
    </div>

    <!--
    <div style="position: absolute; bottom:50px; font-size: 90px; font-weight: 900; color: #323232; width: 100%; overflow: hidden;">
        raimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamorato
    </div>
        -->
    


    <div fxLayout="row" fxLayoutAlign="space-between center" style="position: absolute; height: 60px; width: 100%; background-color: #323232; bottom: 0px;">
        <div fxLayout="row" fxLayoutAlign="start center" >
            <div (click)="openLink('https://www.facebook.com/raimondoinnamoratosindaco')" style="padding-left: 25px; margin-right: 15px;" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Raimondo Innamorato</div>
            <div (click)="openLink('https://www.facebook.com/Movimento5stelleNoicattaro')" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Movimento 5 Stelle Noicàttaro</div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center"> 
            <a style="font-size: 13px; color:white; text-decoration:underline; margin-right: 5px; cursor: pointer;" (click)="openCookie()">Cookie</a>
            <a target="blank" href="https://www.iubenda.com/privacy-policy/32880717" style="font-size: 13px; color:white; margin-right: 5px;" title="Privacy Policy">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
            <div style="font-size: 13px; color:white; padding-right: 25px;">
                <div>&copy; 2021</div>
            </div>
        </div>
    </div>
</section>


<section fxHide fxShow.lt-md style="width: 100%; height: 100vh; overflow-y: scroll; position: relative; background-image: url('./assets/img/sfondo.png');  background-repeat: repeat; background-size: 600px 70px; ">
    
    <!--
    <div style="font-size: 90px; font-weight: 900; color: #323232; width: 100%; overflow: hidden;">
        raimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamorato
    </div>

    <div style="position: relative; left:500px; transform: rotate(-90deg); font-size: 90px; font-weight: 900; color: #323232; overflow: hidden;">
        raimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamorato
    </div>
    -->

    <div fxLayout="row content" fxLayoutAlign="center start" style="top: 0px; padding-top: 30px; position: relative; text-align: center; border-top: 5px solid #323232;">
        <button (click)="openLink('https://www.comune.noicattaro.bari.it/upload/Noicattaro/gestionedocumentale/PROGRAMMA-INNAMORATORAIMONDO_784_5353.pdf?')" class="button-border">Programma 2016</button>
        <button (click)="openLink('https://www.raimondoinnamorato.it/assets/media/programma_2021.pdf')" class="button-border">Programma 2021</button>
        <button (click)="openModalSuggerimenti()" class="button-border">Suggerimenti</button>
        <button routerLink="/lista-movimento" class="button-border">I candidati</button>
        <button fxLayout="row" fxLayoutAlign="center center" (click)="openModalPartecipa()" class="button-border">
            Partecipa
            <div  fxLayout="row" fxLayoutAlign="center center" style="margin-left: 15px; width: 30px; height: 25px; background-color: white; border-radius: 50%; color:#323232; font-size: 14px; font-weight: 700;">{{numero}}</div>
        </button>
        
        <button fxLayout="row" fxLayoutAlign="center center" routerLink="/candidatura" class="button-border">
            <div>Invia la tua candidatura</div>
        </button>
        
        <!--
        <button fxLayout="row" fxLayoutAlign="center center" (click)="openModalCandidati()" class="button-border">
            <div>Cose fatte</div>
            <div style="margin-left:15px"><i class="fas fa-check"></i></div>
        </button>
        -->
    </div>

    <div style="margin-top: 30px; width: 100%; text-align: center; padding-bottom: 200px;">
        <img src="./assets/img/Noicattaro.png" width="180px">
    
        <div style="padding-left: 15px; padding-right: 15px; margin-top: 20px; background-color: #ec1f24; padding-top: 5px; padding-bottom: 5px; color: white; font-size: 30px; font-weight: 600;">
            Noicàttaro 2016 - 2021 - 2026
        </div>
        <div style="padding-left: 15px; padding-right: 15px; margin-top: 10px; font-size: 20px; font-weight: 700;">
            <div class="font-lobster-30">Una città che cammina...</div>
        </div>
    </div>

    <!--
    <div style="position: absolute; bottom:50px; font-size: 90px; font-weight: 900; color: #323232; width: 100%; overflow: hidden;">
        raimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamoratoraimondoinnamorato
    </div>
        -->

    <div fxLayout="row" fxLayoutAlign="space-between center" style="position: fixed; bottom:0px; margin-top: 60px; height: 60px; width: 100%; background-color: #323232;">
        <div fxLayout="column" fxLayoutAlign="start start" style="padding-left:25px;">
            <div (click)="openLink('https://www.facebook.com/raimondoinnamoratosindaco')" class="font-link"><i style="margin-bottom: 5px; font-size: 16px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Raimondo Innamorato</div>
            <div (click)="openLink('https://www.facebook.com/Movimento5stelleNoicattaro')" class="font-link"><i style="font-size: 16px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Movimento 5 Stelle Noicàttaro</div>
        </div>
        <div style="font-size: 13px; color:white; padding-right: 25px;">
            <div>
                <a (click)="openCookie()">Cookie</a>
            </div>
            <div>
            &copy; 2021
            </div>
        </div>
    </div>

</section>