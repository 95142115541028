<section style="overflow:hidden; height:100%;">
    <div style="text-align: right; position: relative; top:15px; right:15px; font-size: 20px;">
        <i (click)="close()" style="cursor: pointer;" class="fas fa-times"></i>
    </div>
    <div style="height:80vh; overflow-y:scroll; padding-top: 20px; padding-bottom:20px; padding-left:20px; padding-right:20px;" class="scroll-bar">       
        <div style="font-size: 20px; font-weight: 700; margin-bottom: 10px;">Programma 2021</div>
        <div style="color:#323232;">
            Il nuovo programma è in costruzione.<br>
            Partecipa ai Gruppi di Lavoro (GDL) e costruisci insieme a tutta la comunità il programma partecipato.

            <div style="margin-top:40px">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div style="text-align: center; width: 40px; color:#323232;"><i style=" font-size: 24px;" class="fas fa-bicycle"></i></div>
                    <div style="font-weight:600; font-size: 18px;">GDL1</div>
                </div>
                <div>urbanistica, lavori pubblici, sport, cultura, mobilità</div>
            </div>

            <div style="margin-top:15px">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div style="text-align: center; width: 40px; color:#323232;"><i style=" font-size: 24px;" class="fas fa-graduation-cap"></i></div>
                    <div style="font-weight:600; font-size: 18px;">GDL2</div>
                </div>
                scuola, istruzione, servizi sociali, contenzioso  
            </div>

            <div style="margin-top:15px">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div style="text-align: center; width: 40px; color:#323232;"><i style=" font-size: 24px;" class="fas fa-store"></i></div>
                    <div style="font-weight:600; font-size: 18px;">GDL3</div>
                </div>
                commercio, tributi, bilancio, mercato, digitale  
            </div>

            <div style="margin-top:15px">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div style="text-align: center; width: 40px; color:#323232;"><i style=" font-size: 24px;" class="fas fa-tree"></i></div>
                    <div style="font-weight:600; font-size: 18px;">GDL4</div>
                </div>
                agricoltura, rifiuti, ambiente, verde, animali  
            </div>

            <button style="margin-top:60px;" fxLayout="row" fxLayoutAlign="center center" (click)="openModalPartecipa()" class="button-border100">
                <div>Partecipa</div>
            </button>
        </div>
    </div>
</section>