<section style="overflow:hidden; height:100%;">
    <div style="text-align: right; position: relative; top:15px; right:15px; font-size: 20px;">
        <i (click)="close()" style="cursor: pointer;" class="fas fa-times"></i>
    </div>
    <div style="height:80vh; overflow-y:scroll; padding-top: 20px; padding-bottom:20px; padding-left:20px; padding-right:20px;" class="scroll-bar">       
        <div style="font-size: 20px; font-weight: 700; margin-bottom: 10px;">Partecipa</div>
        <div style="color:#323232;">
            Invia i tuoi dati per partecipare ai nostri incontri.<br>
            In questo periodo gli incontri avvengono in video-conferenza.<br>
            Riceverai tramite il tuo numero di telefono o tramite email il link per poter partecipare alle riunioni.

            <form [formGroup]="partecipaForm" (submit)="send()" novalidate>

                <div style="margin-top:40px">
                    <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Nome</div>
                    <input formControlName="nome" placeholder="Il tuo nome" class="input-form"/>
                </div>

                <div style="margin-top:15px">
                    <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Cognome</div>
                    <input formControlName="cognome" placeholder="Il tuo cognome" class="input-form"/>
                </div>

                <div style="margin-top:15px">
                    <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Email</div>
                    <input formControlName="email" placeholder="La tua email" class="input-form"/>
                </div>

                <div style="margin-top:15px">
                    <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Telefono</div>
                    <input formControlName="telefono" placeholder="Il tuo numero di telefono" class="input-form"/>
                </div>

                <div style="width: 100%; margin-top: 15px; font-size: 13px; font-weight: 400;">
                    <table>
                        <tr>
                            <td valign="top">
                                <mat-checkbox formControlName="privacy"></mat-checkbox>
                            </td>
                            <td>
                                <div class="font-privacy">Accetto <a href="https://www.iubenda.com/privacy-policy/32880717" target="blank">l'informativa sulla privacy</a> e acconsento al trattamento dei dati personali ai sensi del GDPR 2016/679</div>
                            </td>
                        </tr>
                    </table>
                </div>
                  

                <div style="color: #ec1f24; font-weight:600; font-size:16px; padding-top: 15px;" *ngIf="error">
                    {{error}}
                </div>

                <button [disabled]="!spinner" style="margin-top:40px;" fxLayout="row" fxLayoutAlign="center center" type="submit" class="button-border100">
                    <div *ngIf="spinner">Invia</div>
                    <div *ngIf="!spinner"><mat-spinner [diameter]="20"></mat-spinner></div>
                </button>
            </form>
        </div>
    </div>
</section>