<section style="height: 100vh; overflow-y: scroll;" fxHide fxShow.md fxShow.gt-md>


    <div style="width: 100%; height: 70vh; background-image: url('./assets/media/img/desktop_raimondo.jpg'); background-size:cover; background-position:center;">
    
    </div>


    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px;">
        <div style="font-size: 46px; font-weight: 800; color:#292929;">
            I candidati consiglieri 
        </div>
        <div style="font-size: 14px; margin-top: 10px;">
            Lista Movimento 5 Stelle
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 40px; margin-bottom: 40px;">
        <div fxFlex="15"></div>
        <div fxFlex="70">
           
            <div fxLayout="row" fxLayoutAlign="center start" >
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_1.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Anna Maria</div>
                        <div style="font-weight: 600; font-size: 20px;">Borgia</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_1.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_2.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Luca</div>
                        <div style="font-weight: 600; font-size: 20px;">Cinquepalmi</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_2.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_3.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Maria Rosa</div>
                        <div style="font-weight: 600; font-size: 20px;">Debellis</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_3.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_4.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Angelica</div>
                        <div style="font-weight: 600; font-size: 20px;">Decaro</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_4.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 30px;">
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_5.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Angela</div>
                        <div style="font-weight: 600; font-size: 20px;">Desimini</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_5.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_6.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Anna Rita</div>
                        <div style="font-weight: 600; font-size: 20px;">Didonna</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_6.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_7.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Nicola</div>
                        <div style="font-weight: 600; font-size: 20px;">Di Pinto</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_7.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_8.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Matteo</div>
                        <div style="font-weight: 600; font-size: 20px;">Fraschini</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_8.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 30px;">
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_9.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Nicola</div>
                        <div style="font-weight: 600; font-size: 20px;">Giannoccaro</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_9.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_10.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Francesca</div>
                        <div style="font-weight: 600; font-size: 20px;">Guido</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_10.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_11.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Giacomo</div>
                        <div style="font-weight: 600; font-size: 20px;">Innamorato</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_11.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_12.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Angelarita</div>
                        <div style="font-weight: 600; font-size: 20px;">Loiotile</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_12.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 30px;">
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_13.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Vincenzo Gabriele</div>
                        <div style="font-weight: 600; font-size: 20px;">Parisi</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_13.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_14.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Andrea</div>
                        <div style="font-weight: 600; font-size: 20px;">Pepe</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_14.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_15.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Michele Junior Fabio</div>
                        <div style="font-weight: 600; font-size: 20px;">Schiavo</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_15.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/1_16.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Mariagrazia</div>
                        <div style="font-weight: 600; font-size: 20px;">Tritto</div>
                        <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/1_16.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
                    </div>
                </div>
            </div>

        </div>
        <div fxFlex="15"></div>
    </div>



    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 50px; height: 60px; width: 100%; background-color: #323232; bottom: 0px;">
        <div fxLayout="row" fxLayoutAlign="start center" >
            <div (click)="openLink('https://www.facebook.com/raimondoinnamoratosindaco')" style="padding-left: 25px; margin-right: 15px;" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Raimondo Innamorato</div>
            <div (click)="openLink('https://www.facebook.com/Movimento5stelleNoicattaro')" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Movimento 5 Stelle Noicàttaro</div>
        </div>
        <div fxLayout="row" style="font-size: 13px; color:white; padding-right: 25px;">
            <div routerLink="/terms" style="padding-left: 25px; margin-right: 15px;" class="font-link">Cookie</div>
            
            <div>&copy; 2021</div>
        </div>
    </div>
</section>





<section style="height: 100vh; overflow-y: scroll;" fxHide fxShow.lt-md>

    <div style="width: 100%; height: 50vh; background-image: url('./assets/media/img/mobile_raimondo.jpg'); background-size:cover; background-position:center;">
    
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px; text-align: center;">
        <div style="font-size: 46px; font-weight: 800; color:#292929; line-height: 41px;">
            I candidati consiglieri
        </div>
        <div style="font-size: 14px; margin-top: 15px;">
            Lista Movimento 5 Stelle
        </div>
    </div>
   

    <div style="padding-left: 15px; padding-right: 15px; margin-top: 40px; margin-bottom: 40px;">
    
        <div style="padding-left: 5px; padding-right: 5px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_1.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Anna Maria</div>
                <div style="font-weight: 600; font-size: 20px;">Borgia</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_1.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_2.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Luca</div>
                <div style="font-weight: 600; font-size: 20px;">Cinquepalmi</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_2.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_3.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Maria Rosa</div>
                <div style="font-weight: 600; font-size: 20px;">Debellis</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_3.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_4.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Angelica</div>
                <div style="font-weight: 600; font-size: 20px;">Decaro</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_4.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>

    
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_5.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Angela</div>
                <div style="font-weight: 600; font-size: 20px;">Desimini</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_5.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_6.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Anna Rita</div>
                <div style="font-weight: 600; font-size: 20px;">Didonna</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_6.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_7.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Nicola</div>
                <div style="font-weight: 600; font-size: 20px;">Di Pinto</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_7.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_8.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Matteo</div>
                <div style="font-weight: 600; font-size: 20px;">Fraschini</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_8.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
    
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_9.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Nicola</div>
                <div style="font-weight: 600; font-size: 20px;">Giannoccaro</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_9.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_10.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Francesca</div>
                <div style="font-weight: 600; font-size: 20px;">Guido</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_10.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_11.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Giacomo</div>
                <div style="font-weight: 600; font-size: 20px;">Innamorato</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_11.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_12.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Angelarita</div>
                <div style="font-weight: 600; font-size: 20px;">Loiotile</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_12.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
    
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_13.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Vincenzo Gabriele</div>
                <div style="font-weight: 600; font-size: 20px;">Parisi</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_13.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_14.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Andrea</div>
                <div style="font-weight: 600; font-size: 20px;">Pepe</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_14.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_15.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Michele Junior Fabio</div>
                <div style="font-weight: 600; font-size: 20px;">Schiavo</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_15.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 250px; background-image: url('./assets/media/foto/1_16.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#e20413; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Mariagrazia</div>
                <div style="font-weight: 600; font-size: 20px;">Tritto</div>
                <div style="background-color: #c00310; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/1_16.pdf')" style="font-weight: 500; font-size: 13px; cursor: pointer;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum Casellario</span></div>
            </div>
        </div>
         
    </div>



    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 50px; height: 60px; width: 100%; background-color: #323232; bottom: 0px;">
        <div fxLayout="row" fxLayoutAlign="start center" >
            <div (click)="openLink('https://www.facebook.com/raimondoinnamoratosindaco')" style="padding-left: 25px; margin-right: 15px;" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Raimondo Innamorato</div>
            <div (click)="openLink('https://www.facebook.com/Movimento5stelleNoicattaro')" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Movimento 5 Stelle Noicàttaro</div>
        </div>
        <div fxLayout="row" style="font-size: 13px; color:white; padding-right: 25px;">
            <div routerLink="/terms" style="padding-left: 25px; margin-right: 15px;" class="font-link">Cookie</div>
            
            <div>&copy; 2021</div>
        </div>
    </div>
</section>