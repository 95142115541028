<section style="height: 100vh; overflow-y: scroll;" fxHide fxShow.md fxShow.gt-md>


    <div style="width: 100%; height: 70vh; background-image: url('./assets/media/img/desktop_raimondo.jpg'); background-size:cover; background-position:center;">
    
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="background-color: #292929; border-top: 3px solid #696868; padding-top: 30px; padding-bottom: 30px; text-align: center;">
        <button (click)="openLink('https://www.comune.noicattaro.bari.it/upload/Noicattaro/gestionedocumentale/PROGRAMMA-INNAMORATORAIMONDO_784_5353.pdf?')" class="button-border-green">Programma 2016</button>
        
        <button (click)="openLink('https://www.raimondoinnamorato.it/assets/media/resoconto_2016-2021.pdf')" class="button-border-red">Resoconto 2016-2021</button>
        
        <button (click)="openLink('https://drive.google.com/file/d/1eWi1VQtd23LeT27tWErFBTOsjikkBnw6/view?usp=sharing')" class="button-border-yellow">Il racconto di fine mandato</button>

        <button (click)="openLink('https://www.raimondoinnamorato.it/programma_2021.pdf')" class="button-border-blu">Programma 2021</button>
    </div>


    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px;">
        <div style="font-size: 46px; font-weight: 800; color:#292929;">
            I candidati consiglieri 
        </div>
        <div style="font-size: 14px; margin-top: 10px;">
            Lista Con Raimondo
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; margin-top: 40px; margin-bottom: 40px;">
        <div fxFlex="15"></div>
        <div fxFlex="70">
           
            <div fxLayout="row" fxLayoutAlign="center start" >
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_1.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Toni</div>
                        <div style="font-weight: 600; font-size: 20px;">Ciavarella</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_2.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Pasquale</div>
                        <div style="font-weight: 600; font-size: 20px;">Curlo</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/2_2.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_3.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Annalisa</div>
                        <div style="font-weight: 600; font-size: 20px;">De Caro</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/2_3.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_4.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Giuseppe</div>
                        <div style="font-weight: 600; font-size: 20px;">Didonna</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 30px;">
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_5.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Rosa Anna</div>
                        <div style="font-weight: 600; font-size: 20px;">Dipierro</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/2_5.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_6.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Rosa Anna</div>
                        <div style="font-weight: 600; font-size: 20px;">Leone</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_7.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Francesco</div>
                        <div style="font-weight: 600; font-size: 20px;">Lozupone</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/2_7.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_8.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Giuseppe</div>
                        <div style="font-weight: 600; font-size: 20px;">Masotti</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 30px;">
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_9.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Michele</div>
                        <div style="font-weight: 600; font-size: 20px;">Porcelli</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_10.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Serafina (detta Sara)</div>
                        <div style="font-weight: 600; font-size: 20px;">Pugliese</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_11.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Luigi</div>
                        <div style="font-weight: 600; font-size: 20px;">Rescina</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_12.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Raimondo</div>
                        <div style="font-weight: 600; font-size: 20px;">Romano</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center start" style="margin-top: 30px;">
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_13.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Stefania</div>
                        <div style="font-weight: 600; font-size: 20px;">Sanapo</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div (click)="openLink('./assets/media/cv/2_13.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_14.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Michele</div>
                        <div style="font-weight: 600; font-size: 20px;">Tatone</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_15.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Rita</div>
                        <div style="font-weight: 600; font-size: 20px;">Toffaloni</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
                <div fxFlex="25" style="padding-left: 5px; padding-right: 5px;">
                    <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_16.jpg'); background-size:cover; background-position:top;">
    
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                        <div style="font-weight: 400; font-size: 13px;">Giuseppe</div>
                        <div style="font-weight: 600; font-size: 20px;">Urbano</div>
                        <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                        <div style="font-size: 13px;">Curriculum</div>
                        <div style="font-size: 13px;">Carichi pendenti</div>
                    </div>
                </div>
            </div>

        </div>
        <div fxFlex="15"></div>
    </div>



    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 50px; height: 60px; width: 100%; background-color: #323232; bottom: 0px;">
        <div fxLayout="row" fxLayoutAlign="start center" >
            <div (click)="openLink('https://www.facebook.com/raimondoinnamoratosindaco')" style="padding-left: 25px; margin-right: 15px;" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Raimondo Innamorato</div>
            <div (click)="openLink('https://www.facebook.com/Movimento5stelleNoicattaro')" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Movimento 5 Stelle Noicàttaro</div>
        </div>
        <div fxLayout="row" style="font-size: 13px; color:white; padding-right: 25px;">
            <div routerLink="/terms" style="padding-left: 25px; margin-right: 15px;" class="font-link">Cookie</div>
            
            <div>&copy; 2021</div>
        </div>
    </div>
</section>





<section style="height: 100vh; overflow-y: scroll;" fxHide fxShow.lt-md>

    <div style="width: 100%; height: 50vh; background-image: url('./assets/media/img/mobile_raimondo.jpg'); background-size:cover; background-position:center;">
    
    </div>

    <div fxLayout="row container" fxLayoutAlign="center center" style="background-color: #292929; border-top: 3px solid #696868; padding-top: 30px; padding-bottom: 30px; text-align: center;">
        <button (click)="openLink('https://www.comune.noicattaro.bari.it/upload/Noicattaro/gestionedocumentale/PROGRAMMA-INNAMORATORAIMONDO_784_5353.pdf?')" class="button-border-green">Programma 2016</button>
        
        <button (click)="openLink('https://www.raimondoinnamorato.it/assets/media/resoconto_2016-2021.pdf')" class="button-border-red">Resoconto 2016-2021</button>
        
        <button (click)="openLink('https://drive.google.com/file/d/1eWi1VQtd23LeT27tWErFBTOsjikkBnw6/view?usp=sharing')" class="button-border-yellow">Il racconto di fine mandato</button>
   
        <button (click)="openLink('https://www.raimondoinnamorato.it/programma_2021.pdf')" class="button-border-blu">Programma 2021</button>
    </div>


    <div fxLayout="column" fxLayoutAlign="center center" style="margin-top: 40px; text-align: center;">
        <div style="font-size: 46px; font-weight: 800; color:#292929; line-height: 41px;">
            I candidati consiglieri
        </div>
        <div style="font-size: 14px; margin-top: 15px;">
            Lista Movimento 5 Stelle
        </div>
    </div>
   
    <div style="padding-left: 15px; padding-right: 15px; margin-top: 40px; margin-bottom: 40px;">
    
        <div style="padding-left: 5px; padding-right: 5px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_1.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Toni</div>
                <div style="font-weight: 600; font-size: 20px;">Ciavarella</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_2.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Pasquale</div>
                <div style="font-weight: 600; font-size: 20px;">Curlo</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/2_2.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_3.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Annalisa</div>
                <div style="font-weight: 600; font-size: 20px;">De Caro</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/2_3.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_4.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-right: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Giuseppe</div>
                <div style="font-weight: 600; font-size: 20px;">Didonna</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
 

        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_5.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Rosa Anna</div>
                <div style="font-weight: 600; font-size: 20px;">Dipierro</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/2_5.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_6.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Rosa Anna</div>
                <div style="font-weight: 600; font-size: 20px;">Leone</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_7.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Francesco</div>
                <div style="font-weight: 600; font-size: 20px;">Lozupone</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div (click)="openLink('./assets/media/cv/2_7.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_8.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Giuseppe</div>
                <div style="font-weight: 600; font-size: 20px;">Masotti</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>



        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_9.jpg'); background-size:cover; background-position:top;">

            </div>
            <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Michele</div>
                <div style="font-weight: 600; font-size: 20px;">Porcelli</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_10.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Serafina (detta Sara)</div>
                <div style="font-weight: 600; font-size: 20px;">Pugliese</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div  style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_11.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Luigi</div>
                <div style="font-weight: 600; font-size: 20px;">Rescina</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>
        <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
            <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_12.jpg'); background-size:cover; background-position:top;">

            </div>
            <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
                <div style="font-weight: 400; font-size: 13px;">Raimondo</div>
                <div style="font-weight: 600; font-size: 20px;">Romano</div>
                <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
                <div style="font-size: 13px;">Curriculum</div>
                <div style="font-size: 13px;">Carichi pendenti</div>
            </div>
        </div>

   
    <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px; ">
        <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_13.jpg'); background-size:cover; background-position:top;">

        </div>
        <div  fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
            <div style="font-weight: 400; font-size: 13px;">Stefania</div>
            <div style="font-weight: 600; font-size: 20px;">Sanapo</div>
            <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
            <div (click)="openLink('./assets/media/cv/2_13.pdf')" style="font-weight: 500; font-size: 13px;"><i class="fas fa-file-pdf"></i><span style="margin-left:5px; text-decoration: underline;">Curriculum</span></div>
            <div style="font-size: 13px;">Carichi pendenti</div>
        </div>
    </div>
    <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
        <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_14.jpg'); background-size:cover; background-position:top;">

        </div>
        <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
            <div style="font-weight: 400; font-size: 13px;">Michele</div>
            <div style="font-weight: 600; font-size: 20px;">Tatone</div>
            <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
            <div style="font-size: 13px;">Curriculum</div>
            <div style="font-size: 13px;">Carichi pendenti</div>
        </div>
    </div>
    <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
        <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_15.jpg'); background-size:cover; background-position:top;">

        </div>
        <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
            <div style="font-weight: 400; font-size: 13px;">Rita</div>
            <div style="font-weight: 600; font-size: 20px;">Toffaloni</div>
            <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
            <div style="font-size: 13px;">Curriculum</div>
            <div style="font-size: 13px;">Carichi pendenti</div>
        </div>
    </div>
    <div style="padding-left: 5px; padding-right: 5px; margin-top: 15px;">
        <div style="border-top-left-radius: 10px; border-top-right-radius: 10px; width: 100%; height: 200px; background-image: url('./assets/media/foto/2_16.jpg'); background-size:cover; background-position:top;">

        </div>
        <div fxLayout="column" fxLayoutAlign="center left" style="padding-left: 15px; padding-top: 5px; padding-bottom: 5px; color:white; background-color:#ff9c00; border-bottom-right-radius:10px; border-bottom-left-radius:10px; ">
            <div style="font-weight: 400; font-size: 13px;">Giuseppe</div>
            <div style="font-weight: 600; font-size: 20px;">Urbano</div>
            <div style="background-color: #df8a04; width: 100%; height: 1px; margin-top: 10px; margin-bottom: 10px;"></div>
            <div style="font-size: 13px;">Curriculum</div>
            <div style="font-size: 13px;">Carichi pendenti</div>
        </div>
    </div>

</div>
        
         




    <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 50px; height: 60px; width: 100%; background-color: #323232; bottom: 0px;">
        <div fxLayout="row" fxLayoutAlign="start center" >
            <div (click)="openLink('https://www.facebook.com/raimondoinnamoratosindaco')" style="padding-left: 25px; margin-right: 15px;" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Raimondo Innamorato</div>
            <div (click)="openLink('https://www.facebook.com/Movimento5stelleNoicattaro')" class="font-link"><i style="font-size: 18px;" class="fab fa-facebook"></i>&nbsp;&nbsp;Movimento 5 Stelle Noicàttaro</div>
        </div>
        <div fxLayout="row" style="font-size: 13px; color:white; padding-right: 25px;">
            <div routerLink="/terms" style="padding-left: 25px; margin-right: 15px;" class="font-link">Cookie</div>
            
            <div>&copy; 2021</div>
        </div>
    </div>
</section>