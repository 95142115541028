<div style="font-size: 14px; background-color: white; padding-top: 40px; padding-bottom: 40px; padding-left: 15px; padding-right: 15px;">
    
    <div style="font-weight: 600; font-size: 16px; margin-bottom: 10px;">COOKIE POLICY - www.noicattaro5stelle.it - www.raimondoinnamorato.it</div>

    <div style="font-weight:600;">Informazioni generali</div>
    Un cookie è un file che viene memorizzato sul computer dell'utente. Questo file viene generato e salvato automaticamente durante la navigazione e bisogna specificare se non si desidera che questo file venga salvato. Un cookie è un file essenziale per la navigazione del sito, in quanto aiuto e supporto l'utente memorizzando alcune informazioni che sono funzionali alla navigazione. L'applicazione web si può personalizzare in base alle tue esigenze, raccogliendo e ricordando le informazioni sulle tue preferenze. Utilizziamo i cookie del traffico per identificare le pagine in uso. Ciò consente di analizzare i dati relativi al traffico delle pagine web e migliorare il nostro sito web per adattarlo alle esigenze dei clienti. Utilizziamo queste informazioni solo per scopi di analisi statistica, anche grazie a cookie di terze parti. Nel complesso, i cookie ci aiutano a fornirti un sito web migliore, permettendoci di monitorare quali pagine trovate utili e che non lo fai. Un cookie non consente in alcun modo di accedere al tuo computer o di qualsiasi informazione su di te, ad eccezione dei dati che hai scelto di condividere con noi. Si può scegliere di accettare o rifiutare i cookies. La maggior parte dei browser web accetta automaticamente i cookie, ma di solito puoi modificare l'impostazione del browser per non accettare i cookie se preferisci. Ciò potrebbe impedirti di sfruttare appieno il sito.

    <div style="font-weight: 600; margin-top: 15px;">Uso dei cookie</div>
    I dati personali trasmessi dagli utenti del sito web "www.noicattaro5stelle.it" al momento della compilazione di form, ordini, questionari o richieste, o attraverso l'invio di messaggi di posta elettronica (di seguito, i "Dati Personali") saranno acquisiti e trattati da: Raimondo Innamorato, in qualità di titolare del trattamento dei dati personali. Il trattamento dei Dati Personali sarà soggetto alla legge italiana (Decreto Legislativo n. 196/03). Conformemente all'impegno e alla cura che Raimondo Innamorato dedica alla tutela dei dati personali.
    La informiamo di seguito sulle modalità, finalità e ambito di comunicazione e diffusione dei Suoi dati personali e sui Suoi diritti, in conformità all'art. 13 del GDPR 196/2016.


    <br>- Attività accessorie<br>
    I dati possono essere comunicati a terze parti che svolgono funzioni necessarie a rendere il servizio operativo.
    L'obiettivo è permettere la fruizione degli obiettivi specificati nel sito e consentire a terze parti di svolgere attività tecniche, logistiche e di altro tipo.
    <br><br>

    -Raccolti dati anche in maniera automatica
    <br>Durante la navigazione degli Utenti possono essere raccolte le seguenti informazioni che vengono conservate nei file di log del server (hosting) del sito: (nome, cognome, email, professione, indirizzo, paese, telefono, documento di identità).

    

</div>