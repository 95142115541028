<section style="overflow:hidden; height:100%;">
    <div style="text-align: right; position: relative; top:15px; right:15px; font-size: 20px;">
        <i (click)="close()" style="cursor: pointer;" class="fas fa-times"></i>
    </div>
    <div style="height:80vh; overflow-y:scroll; padding-top: 20px; padding-bottom:20px; padding-left:20px; padding-right:20px;" class="scroll-bar">       
        <div style="font-size: 20px; font-weight: 700; margin-bottom: 10px;">Invia la tua candidatura</div>
        <div style="color:#323232;">
            A breve sarà possibile inoltrare la propria candidatura come consigliere comunale per le elezioni comunali a Noicàttaro 2021-2026.<br>
            <br><br>
            Sarà data a tutti la possibilità di candidarsi cosi come avvenuto nel 2016, in linea con una partecipazione democratica e trasparente che lega da sempre il nostro gruppo.<br>
            <br>
            
        </div>
    </div>
</section>