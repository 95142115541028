import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CookieComponent } from './pages/terms/cookie/cookie.component';
import { CandidaturaComponent } from './pages/candidatura/candidatura.component';
import { ListaMovimentoComponent } from './pages/lista-movimento/lista-movimento.component';
import { ListaConraimondoComponent } from './pages/lista-conraimondo/lista-conraimondo.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  //{ path: 'home', component: HomeComponent},
  { path: 'home', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent},
  { path: 'cookie', component: CookieComponent},
  { path: 'candidatura', component: CandidaturaComponent},
  { path: 'lista-movimento', component: ListaMovimentoComponent},
  { path: 'lista-conraimondo', component: ListaConraimondoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
