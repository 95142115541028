import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-suggerimenti',
  templateUrl: './modal-suggerimenti.component.html',
  styleUrls: ['./modal-suggerimenti.component.scss']
})
export class ModalSuggerimentiComponent implements OnInit {

  public suggerimentiForm: FormGroup;

  constructor(public db: AngularFireDatabase, public formBuilder: FormBuilder, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ModalSuggerimentiComponent>, @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.suggerimentiForm = formBuilder.group({
      nome : new FormControl('', Validators.compose([Validators.required])),
      cognome : new FormControl('', Validators.compose([Validators.required])),
      email : new FormControl('', Validators.compose([Validators.required])),
      telefono : new FormControl('', Validators.compose([Validators.required])),
      messaggio: new FormControl('', Validators.compose([Validators.required])),
      privacy: new FormControl(false),
    });
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  public error;
  public spinner = true;
  send(){
    var self = this;
    this.error = null;
    if(this.suggerimentiForm.valid){
      if(this.suggerimentiForm.value.privacy){
        this.spinner = true;
        var data = Date.now();
        var docData = {
          created_at: data,
          nome: this.suggerimentiForm.value.nome,
          cognome: this.suggerimentiForm.value.cognome,
          email: this.suggerimentiForm.value.email,
          telefono: this.suggerimentiForm.value.telefono,
          messaggio: this.suggerimentiForm.value.messaggio,
        }

        this.db.list('suggerimenti/').push(docData).then(function(snapshot) {
          console.log(snapshot);
          let snackBarRef = self.snackBar.open('Il tuo suggerimento è stato inviato.');
          self.dialogRef.close();
          self.spinner = false;
        });
      }else{
        this.error = "Accetta l'informativa sulla privacy.";
      }
    }else{
      this.error = 'Compila tutti i campi richiesti.';
    }
  }
}
