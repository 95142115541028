import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-movimento',
  templateUrl: './lista-movimento.component.html',
  styleUrls: ['./lista-movimento.component.scss']
})
export class ListaMovimentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openLink(link){
    window.open(link);
  }
}
