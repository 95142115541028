<section>
    <div style="padding-right: 20px; padding-left: 20px; padding-top: 20px; padding-bottom: 20px;">
        <button class="button-back" routerLink="/home">
            <i class="fas fa-chevron-left"></i>
            INDIETRO
        </button>

        <div style="margin-top: 20px; width: 100%; height:1px; background-color: white;"></div>

    </div>

    <div style="text-align: center; margin-left:20px; margin-right: 20px; margin-bottom: 20px; border-radius: 4px; background-color: #b22525; color:white; font-weight:600; font-size:16px; padding-top: 10px; padding-bottom: 10px;">
        Candidature chiuse
    </div>

    <div fxLayout="row" fxLayoutAlign="center start" style="padding-left: 20px; padding-right: 20px; padding-bottom: 60px;">
        <div style="width: 100%; background-color: white; border-radius: 4px; border:1px solid #b6b6b6; ">
            <div style="padding-top: 20px; padding-bottom: 20px; padding-left: 20px; padding-right: 20px;">
                <div style="text-align: center;">
                    <img src="./assets/img/Noicattaro.png" width="80px">
                </div>
                <div style="text-align: center; margin-top: 20px; font-size: 20px; font-weight: 700; margin-bottom: 10px;">
                    Amministrative Noicàttaro 2021
                </div>
                <div style="margin-top: 20px; font-size: 20px; font-weight: 700; margin-bottom: 10px;">
                    Invia la tua candidatura
                </div>
                <div style="margin-bottom: 20px;">
                    Compila i campi qui sotto e allega un tuo documento di identità.
                    <br>Possono avanzare la loro candidatura coloro che:
                    <br><span style="font-weight: 600;">1.</span> Non abbiano mai partecipato a elezioni di qualsiasi livello contro il Movimento 5 Stelle
                    <br><span style="font-weight: 600;">2.</span> Non abbiano riportato una condanna penale anche con la sola sentenza di primo grado
                    <br><span style="font-weight: 600;">3.</span> Non abbiano tenuto condotte in contrasto con i principi, valori, programmi, nonchè con l'immagine del Movimento 5 Stelle, del suo simbolo e del suo garante
                    <br><span style="font-weight: 600;">4.</span> Non si siano dimessi o abbiano rinunciato ad una carica elettiva del Movimento 5 Stelle.
                    <div style="margin-top:5px">
                        <br>Potrai inviare la candidatura dalle <span style="font-weight: 600;">ore 14:00 del 18 Gennaio 2021</span> alle <span style="font-weight: 600;">ore 14:00 del 28 Febbraio 2021</span>.
                        <br>Al termine della raccolta delle candidature, riceverai una email con le indicazioni per poter comporre la lista definitiva.
                    </div>
                </div>

                <div fxLayout="column" fxLayoutAlign="center center" style="width:100%; position: relative; float:left; margin-bottom:20px;">
                    <label *ngIf="!spinnerFile && fileLenght==0" style="cursor: pointer;">
                        <input type="file" (change)="onFileChangedFile($event)"/>
                        <div fxLayout="row" fxLayoutAlign="center center" class="button-upload">
                            <i style="margin-right: 10px;" class="fas fa-id-card"></i>
                            <div >Carica un documento di identità</div>
                        </div>                  
                    </label>

                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="spinnerFile" style="width: 295px; border-radius: 4px; border:2px solid #b6b6b6; padding-top: 12px; padding-bottom: 12px;">
                        <mat-spinner [diameter]="20"></mat-spinner>
                        <div style="margin-left: 5px; font-weight: 600; color:#323232;">in caricamento...</div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="fileLenght!=0 && !spinnerFile">
                        <button class="button-back" (click)="open(file[0].url)">
                            Documento caricato
                        </button>
                        <button style="margin-left: 10px;" class="button-back" (click)="removeFile(file[0].name_file)">
                            <div><i class="fas fa-times"></i></div>
                        </button>
                    </div>
                </div>

                <form [formGroup]="candidaturaForm" (submit)="send()" novalidate>

                    <div style="margin-top:40px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Nome</div>
                        <input formControlName="nome" placeholder="Il tuo nome" class="input-form"/>
                    </div>
    
                    <div style="margin-top:15px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Cognome</div>
                        <input formControlName="cognome" placeholder="Il tuo cognome" class="input-form"/>
                    </div>

                    <div style="margin-top:15px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Paese di residenza</div>
                        <input formControlName="paese" placeholder="Il paese di residenza" class="input-form"/>
                    </div>

                    <div style="margin-top:15px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Indirizzo di residenza</div>
                        <input formControlName="indirizzo" placeholder="Via e civico" class="input-form"/>
                    </div>

                    <div style="margin-top:15px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Professione</div>
                        <input formControlName="professione" placeholder="La tua professione" class="input-form"/>
                    </div>
    
                    <div style="margin-top:15px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Email</div>
                        <input formControlName="email" placeholder="La tua email" class="input-form"/>
                    </div>
    
                    <div style="margin-top:15px">
                        <div style="font-size: 14px; font-weight: 600; color:#323232; margin-bottom: 5px;">Telefono</div>
                        <input formControlName="telefono" placeholder="Il tuo numero di telefono" class="input-form"/>
                    </div>
    
                    <div style="width: 100%; margin-top: 15px; font-size: 13px; font-weight: 400;">
                        <table>
                            <tr>
                                <td valign="top">
                                    <mat-checkbox formControlName="privacy"></mat-checkbox>
                                </td>
                                <td>
                                    <div class="font-privacy">Accetto <a href="https://www.iubenda.com/privacy-policy/32880717" target="blank">l'informativa sulla privacy</a> e acconsento al trattamento dei dati personali ai sensi del GDPR 2016/679</div>
                                </td>
                            </tr>
                        </table>
                    </div>
    
                    <div style="color: #ec1f24; font-weight:600; font-size:16px; padding-top: 15px;" *ngIf="error">
                        {{error}}
                    </div>
     
                    <button disabled="true" style="margin-top:40px;" fxLayout="row" fxLayoutAlign="center center" type="submit" class="button-border100">
                        <div *ngIf="spinner">Invia</div>
                        <div *ngIf="!spinner"><mat-spinner [diameter]="20"></mat-spinner></div>
                    </button>

                    <div style="text-align: center; color: #ec1f24; font-size: 14px; font-weight: 600;">
                        Inoltro delle candidature terminato il 28 Febbraio 2021 alle ore 14:00
                    </div>
           
                </form>

            </div>
        </div>
    </div>
</section>