import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lista-conraimondo',
  templateUrl: './lista-conraimondo.component.html',
  styleUrls: ['./lista-conraimondo.component.scss']
})
export class ListaConraimondoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openLink(link){
    window.open(link);
  }
}
