import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { ModalProgramComponent } from '../../pages/modal-program/modal-program.component';
import { ModalPartecipaComponent } from '../../pages/modal-partecipa/modal-partecipa.component';
import { ModalCandidatiComponent } from '../../pages/modal-candidati/modal-candidati.component';
import { ModalSuggerimentiComponent } from '../../pages/modal-suggerimenti/modal-suggerimenti.component';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  
  public numero;

  constructor(public db: AngularFireDatabase, public dialog: MatDialog) {
    var self = this;
    this.db.object('partecipa').valueChanges().subscribe( action =>{
      var number = Object.keys(action).length;
      self.numero = 422 + number;
    });
  }

  ngOnInit(): void {
  }

  openLink(link){
    window.open(link);
  }

  openModalProgram() {
    let dialogRef = this.dialog.open(ModalProgramComponent, { 
      width: 'auto',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openModalCandidati() {
    let dialogRef = this.dialog.open(ModalCandidatiComponent, { 
      //width: '346px',
      width: 'auto',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openModalPartecipa() {
    let dialogRef = this.dialog.open(ModalPartecipaComponent, { 
      width: 'auto',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openModalSuggerimenti(){
    let dialogRef = this.dialog.open(ModalSuggerimentiComponent, { 
      width: 'auto',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openCookie(){
    window.open('https://www.noicattaro5stelle.it/cookie');
  }
}
