import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { ModalPartecipaComponent } from '../../pages/modal-partecipa/modal-partecipa.component';
import { MatSnackBar, MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-program',
  templateUrl: './modal-program.component.html',
  styleUrls: ['./modal-program.component.scss']
})
export class ModalProgramComponent implements OnInit {

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<ModalProgramComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
  
  openModalPartecipa() {
    this.dialog.closeAll();
    let dialogRef = this.dialog.open(ModalPartecipaComponent, { 
      width: '346px',
      height: 'auto',
    })
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
